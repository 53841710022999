const production = [
  "/article*",
  "/cs/news*",
  "/dota/news*",
  "/dota/heroes*",
  "/supercell/news/*",
  "/quiz/*",
  "/gaming*",
  "/cs-quiz*",
  "/cs/bounty-simulator*",
  "/counter-strikle",
  "/counter-strikle/",
  "/counter-strikle/multiplayer*",
  "/rl/tournaments/*",
  "/rl/stats/*",
  "/rl/leaderboard*",

  // Tournament routes
  // These need to be handled differently to avoid matching child routes
  "/cs/tournaments/*",
  "/cs/simulator/*",
  "/dota/tournaments/*",
];

export const ssrRoutesPatterns = {
  production,
  development: [...production],
  local: [...production],
};
