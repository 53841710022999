import { ssrRoutesPatterns } from "./ssrRoutesPatterns";

export const isSsrPage = ({
  pathname,
  environment,
}: {
  pathname?: string | null;
  environment: "development" | "production" | "local";
}) => {
  if (!pathname) {
    return false;
  }

  const routesPatterns = ssrRoutesPatterns[environment];

  return routesPatterns.some((pattern) => {
    if (pattern.includes("/tournaments/")) {
      // Only one level of child route is pointed to ssr
      // i.e. /cs/tournaments/{tournamentId} not /cs/tournaments/{tournamentId}/match/{matchId}
      const basePattern = pattern.replace("/*", "/[^/]+$");
      return new RegExp(`^${basePattern}`).test(pathname);
    }

    const regex = new RegExp(`^${pattern.replace(/\*/g, ".*")}$`);
    return regex.test(pathname);
  });
};
