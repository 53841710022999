import { Link } from "@remix-run/react";
import { DetailedHTMLProps } from "react";
import { getConfig } from "../../../config";
import { isSsrPage } from "@portal-frontend-ssr/helpers";

export const AdaptiveLink = ({
  to,
  children,
  className,
  isExternal,
  onClick,
}: {
  to: string;
  isExternal?: boolean;
} & DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
  const isSsr = isSsrPage({ pathname: to, environment: getConfig().ENVIRONMENT });

  if (isExternal) {
    return (
      <a target="_blank" rel="noreferrer" href={to} className={className}>
        {children}
      </a>
    );
  }

  if (!isSsr) {
    return (
      <a href={`${getConfig().SPA_SITE_URL}${to}`} className={className} onClick={onClick}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};
